import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      all: 'All',
      residential: 'Residential',
      commercial: 'Commercial',
      accommodation: 'Accommodation',
      completed: 'Completed',
      ongoing: 'Ongoing',
      home: 'Home',
      projects: 'Projects',
      contact: 'Contact',
      studio: 'Studio',
      design_team: 'Design Team',
      construction_team: 'Construction Team',
      photographer: 'Photographer',
      size: 'Size',
      city: 'Heraklion',
      street: 'Epimenidou 42',
      county: 'Crete',
      postcode: 'P 71202',
      location: 'Location',
      type: 'Type',
      year: 'Year',
      status: 'Status',
      publications_title: 'Publications and awards',
      publications: 'Publications',
      studio_desc_title: 'ENTOPOS:  < EN + TOPOS, ΕΝ=ΙΝ, ΤOPOS=PLACE',
      studio_desc: 'Topos is the greek word for place. However, except from this literal translation, it is a word that is closely related to meanings such as history, memory, tradition, culture landscape, materiality, people.\nThis is the main reason why each project is unique, for us. Because it is being realized in a specific “topos”-site with specific needs and requirements. And that is why the starting point of each architectural synthesis in our studio is the analysis of this “topos” and the personal experience when visiting it, taking always into consideration the contemporary needs and techniques.\n\nMainly based in Heraklion of Crete, our studio has been undertaking architectural studies, supervision and construction since 2015 and has a sufficient number of collaborators and professional experts for each operation needed during the design and construction process. ',
      studio_team_title: 'STUDIO',
      yannadakis: 'Michalis Yannadakis',
      lemonis: 'Panagiotis Lemonis',
      karfi: 'Christiana Karfi',
      andreopoulou: 'Ourania Andreopoulou',
      klinaki: 'Victoria Klinaki',
      kouros: 'Demosthenes Kouros',
    },
  },
  el: {
    translation: {
      all: 'Όλα',
      residential: 'Κατοικίες',
      commercial: 'Καταστήματα',
      accommodation: 'Φιλοξενία',
      completed: 'Ολοκληρωμένα',
      ongoing: 'Σε εξέλιξη',
      home: 'Αρχική',
      projects: 'Έργα',
      contact: 'Επικοινωνία',
      studio: 'Ομάδα',
      design_team: 'Ομάδα Σχεδιασμού',
      construction_team: 'Ομάδα Κατασκευής',
      photographer: 'Φωτογράφος',
      size: 'Μέγεθος',
      city: 'Ηρακλείο',
      street: 'Επιμενίδου 42',
      county: 'Κρήτη',
      postcode: 'ΤΚ 71202',
      location: 'Τοποθεσία',
      type: 'Τύπος',
      year: 'Χρονιά',
      status: 'Κατάσταση',
      publications_title: 'Δημοσιέυσεις και βραβεία',
      publications: 'Δημοσιεύσεις',
      studio_desc_title: 'ENTOPOS:  < EN + TOPOS',
      studio_desc: 'Ο όρος «τόπος» αποτελεί έννοια πολυδιάστατη, η οποία εκτός από το χωρικό προσδιορισμό ενός μέρους, εμπεριέχει ποικίλες έννοιες στενά συνδεδεμένες με αυτό, όπως η ιστορία, η μνήμη, η παράδοση, το φυσικό τοπίο, η υλικότητα, οι κάτοικοι, η κουλτούρα.\nΗ ανάλυση αυτού του «τόπου» και η βιωματική εμπειρία κατά την επίσκεψή μας σε αυτόν, αποτελούν την αφετηρία κάθε αρχιτεκτονικής μας σύνθεσης. Γι’ αυτό το λόγο και κάθε μελέτη είναι μοναδική, για εμάς. Επειδή πραγματοποιείται σε ένα συγκεκριμένο «τόπο», με όλες τις ιδιαιτερότητες, ανάγκες και απαιτήσεις που τον πλαισιώνουν.\n\nΜε έδρα το Ηράκλειο Κρήτης, από το 2015, το γραφείο μας αναλαμβάνει την αρχιτεκτονική μελέτη, επίβλεψη και κατασκευή, πάντα σε συνεργασία με ικανό αριθμό συνεργατών, μηχανικών και των απαραίτητων ειδικών για τη διεξαγωγή όλων των απαραίτητων εργασιών από το στάδιο της προμελέτης μέχρι το στάδιο της κατασκευής.',
      studio_team_title: 'ΟΜΑΔΑ',
      yannadakis: 'Μιχάλης Γιανναδάκης',
      lemonis: 'Παναγιώτης Λεμονής',
      karfi: 'Χριστιάνα Καρφή',
      andreopoulou: 'Ουρανία Ανδρεοπούλου',
      klinaki: 'Βικτωρία Κλινάκη',
      kouros: 'Δημοσθένης Κούρος',
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'el',
    ns: 'test',
    fallbackLng: 'en',
    partialBundledLanguages: true,
  });

export default i18n;
