import React, {
  useEffect, useRef, useCallback, useState,
} from 'react';
import { Fade } from 'react-slideshow-image';
import {
  Spin, Col, Row,
  Image as AntdImage,
} from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  isMobile,
} from 'react-device-detect';
// import Aimg from '../assets/images/landscape/A.jpg';
// import Bimg from '../assets/images/landscape/B.jpg';
// import Cimg from '../assets/images/landscape/C.jpg';
// import Dimg from '../assets/images/landscape/D.jpg';
// import Apimg from '../assets/images/portrait/A.jpg';
// import Bpimg from '../assets/images/portrait/B.jpg';
// import Cpimg from '../assets/images/portrait/C.jpg';
// import Dpimg from '../assets/images/portrait/D.jpg';
// import 'react-slideshow-image/dist/styles.css';
import './homepage.css';

import { useTranslation } from 'react-i18next';
import smoothscroll from 'smoothscroll-polyfill';
import * as FastClick from 'fastclick';
import {
  GetImages, GetNumOfImages, GetHomepageProjects, GetHomepageProject, GetProject, AddLanguageResourcesHomepage, sleep,
} from '../utils';

const loadingIcon = <LoadingOutlined style={{ fontSize: 42, color: '#232323', marginTop: 35 }} spin />;

function HomePage() {
  const { t } = useTranslation();
  const [images, setImages] = useState('');
  const [scrollPos, setScrollPos] = useState(null);
  const projectRef = useRef(null);
  const projectpageRef = useRef(null);
  const analysisCnt = useRef(null);
  const startY = useRef(null);
  const prevStartY = useRef(null);
  const delta = useRef(null);
  const startTime = useRef(null);
  const endTime = useRef(null);
  const scrollRef = useRef(null);
  const multiplier = useRef(1);
  const indexRef = useRef(null);
  const startTouchEnd = useRef(null);
  const scrolling = useRef(false);
  const [newImagesLoading, setNewImagesLoading] = useState(false);
  const touchError = 5;
  const i_speedLimit = 1.2;
  const i_moveThreshold = 100;
  const i_offsetThreshold = 30;
  const i_acceleration = 0.5;
  const i_accelerationT = 300;
  let acc = null;
  const divContainerRef = useRef();
  const imageColRef = useRef();
  const [dimensions, setDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (imageColRef.current) {
        setDimensions({
          width: imageColRef.current.clientWidth,
          height: imageColRef.current.clientHeight,
        });
      }
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const onWheel = (e) => {
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;
    setScrollPos(containerScrollPosition);

    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
    });
  };

  /* touchend – calculate touchtime by comparing the current time to the last touchmove time,
   calculate offset by taking the acceleration to the power of 2, times the window height or the
   scrolling element’s width, depending on direction. Then, scroll the element for an extra
   amount of pixels (offset), using quartic ‘out’ easing (which seems to best match the native
   momentum scrolling curve), for a pre-set duration. */

  const normalize = (enteredValue, minEntry = 10, maxEntry = 3000, normalizedMin = 0, normalizedMax = 1) => {
    const mx = (enteredValue - minEntry) / (maxEntry - minEntry);

    const preshiftNormalized = mx * (normalizedMax - normalizedMin);

    const shiftedNormalized = preshiftNormalized + normalizedMin;

    return shiftedNormalized;
  };

  const onTouchStart = (e) => {
    startTouchEnd.current = false;
    // e.preventDefault();
    startY.current = e.touches[0].clientY;
    prevStartY.current = e.touches[0].clientY;
    startTime.current = new Date().getTime();
    if (scrolling.current && (startTime.current - endTime.current) < i_accelerationT) {
      // user swiped while still animating, increase the multiplier for the offset
      multiplier.current += i_acceleration;
    } else {
      // else reset multiplier
      multiplier.current = 1;
    }
  };

  const onTouchMove = (e) => {
    // e.preventDefault();
    endTime.current = new Date().getTime();
    // if (endTime.current - startTime.current < 200) {
    //   return;
    // }
    // startTouchEnd.current = true;
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;
    const currentY = e.changedTouches[0].clientY;
    delta.current = (currentY - prevStartY.current);
    acc = Math.abs(delta.current / (endTime.current - startTime.current));

    container.scrollTo({
      top: 0,
      left: containerScrollPosition - delta.current,
    });
    prevStartY.current = e.touches[0].clientY;
  };
  const onTouchEnd = (e) => {
    startTouchEnd.current = true;
  };

  useEffect(() => {
    let timer;
    scrollRef.current.addEventListener('scroll', () => {
      if (startTouchEnd.current) {
        clearTimeout(timer);

        timer = setTimeout(() => {
          scrolling.current = false;
        }, 300);
      }
    }, { passive: true });
    FastClick.attach(projectpageRef.current);
    projectpageRef.current.addEventListener('wheel', (event) => event.preventDefault());
    projectpageRef.current.style.overflowY = 'hidden';
    async function getImages() {
      const imagesDictUnsorted = await GetHomepageProjects();
      const imagesDictSorted = imagesDictUnsorted.sort((a, b) => ((moment(a.position, 'YYYY-MM-DD HH:mm:ss').toDate() < moment(b.position, 'YYYY-MM-DD HH:mm:ss').toDate()) ? 1 : ((moment(b.position, 'YYYY-MM-DD HH:mm:ss').toDate() < moment(a.position, 'YYYY-MM-DD HH:mm:ss').toDate()) ? -1 : 0)));
      console.log('images unsorted', imagesDictUnsorted);
      console.log('images sorted', imagesDictSorted);
      for (let i = 0; i < imagesDictSorted.length; i += 2) {
        if (i !== 0) {
          setNewImagesLoading(true);
        }
        const imagesSorted = [];
        const image1 = await GetHomepageProject(imagesDictSorted[i].name);
        imagesSorted.push(image1);
        if (i + 1 < imagesDictSorted.length) {
          const image2 = await GetHomepageProject(imagesDictSorted[i + 1].name);
          AddLanguageResourcesHomepage(imagesDictSorted[i + 1], i + 1);
          imagesSorted.push(image2);
        }
        if (i === 0) {
          setImages(imagesSorted);
          const tmpImg = new Image();
          tmpImg.onload = function () {
            const ratio = tmpImg.height / tmpImg.width;
            setDimensions({
              width: imageColRef.current.clientHeight / ratio,
              height: imageColRef.current.clientHeight,
            });
          };
          if (isMobile && imagesSorted[0].image_mob) {
            tmpImg.src = `data:image/jpeg;base64,${imagesSorted[0].image_mob}`;
          } else {
            tmpImg.src = `data:image/jpeg;base64,${imagesSorted[0].image}`;
          }
        } else {
          setImages((oldArray) => [...oldArray, ...imagesSorted]);
        }
      }
      setNewImagesLoading(false);
      // setDimensionsCallback();
    }
    // if (!location.state) {
    indexRef.current = 'current';
    async function getProject() {
      getImages();
    }
    getProject();
  }, []);

  function ImageDiv(image, index) {
    let img = image.image;
    if (isMobile && image.image_mob) {
      img = image.image_mob;
    }
    return (
      <div className="p-wrap-h">
        <Row className="project-image-row-h">
          { typeof image === 'object' && (
          <div ref={(imageColRef)} className="project-image-wrap-h">
            <AntdImage
              className="project-image-h"
              src={`data:image/jpeg;base64,${img}`}
              height="100%"
              key={`image-h${index}`}
              preview={{
                mask: null,
              }}
            />
          </div>
          )}
        </Row>
        <Row className="project-desc-h-row">
          <div className={(isMobile ? 'project-desc-h-mob' : 'project-desc-h')}>
            {t(`img_desc${index}`)}
          </div>
        </Row>
      </div>
    );
  }

  return (
    <>
      <div className="homepage" ref={projectpageRef} onWheel={onWheel}>
        <div className="spin">
          <Spin spinning={!images} indicator={loadingIcon} />
        </div>
        <div ref={divContainerRef} style={{ width: dimensions.width, height: dimensions.height }} className={(isMobile ? 'project-inner-mob' : 'project-inner')}>
          <div className="project-h" ref={scrollRef}>
            <div className="images-h">
              <AntdImage.PreviewGroup>
                <Row className="images-row-h">
                  { images
                && images.map((image, index) => {
                  AddLanguageResourcesHomepage(image, index);
                  image.desc = t(`img_desc${index}`);
                  return (
                    <Col
                      className="image-col-h"
                    >
                      {ImageDiv(image, index)}
                    </Col>
                  );
                })}
                  { newImagesLoading
                  && (
                  <Col className="image-col-h new-spin">
                    <Spin spinning={newImagesLoading} indicator={loadingIcon} />
                  </Col>
                  )}
                </Row>
              </AntdImage.PreviewGroup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
