import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './publications.css';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const Publications = () => {
  const { t } = useTranslation();
  const homeRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();
  const [downArrow, setDownArrow] = useState(true);
  const [upArrow, setUpArrow] = useState(false);

  const scrollCheck = (event) => {
    const up = event.target.scrollTop === 0;
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom) {
      setDownArrow(false);
    } else {
      setDownArrow(true);
    }
    if (up) {
      setUpArrow(false);
    } else {
      setUpArrow(true);
    }
  };

  return (
    <>
      <div className="publications">
        <div className="publications-wrapper">
          <div className="publications-title">{t('publications_title')}</div>
          <div className="publications-arrow-wrap">
            {upArrow && <UpOutlined className="publications-arrow" />}
          </div>
          <div className="publications-container" onScroll={scrollCheck}>
            <div className="publications-items">
              <span className="p-title">Archisearch </span>
              - LMARIS jewelry shop in Blue Palace Hotel – Οκτώβριος 2020
            </div>
            <div className="publications-items">
              <a className="publications-links p-title" href="https://bigsee.eu/category/big-see-architecture-award-2020/" rel="noopener noreferrer" target="_blank">BigSEE Architecture Award 2020 </a>
              - Villa Plaka, Crete – Αύγουστος 2020
            </div>
            <div className="publications-items">
              <a className="publications-links p-title" href="https://www.facebook.com/ktirio.gr/?__cft__%5B0%5D=AZW8iBAX2jhu_clFvMJkZSq6siXsUdVlwexa1fQTHbY9LpsRPOf20LbMpWiOYpzxM4a_Kwv9yq1SIkcr9L7g-5sxbAzMZV1S4D_hirO_M5Th_c3JxyQp-AA5kjai4gE7NIJIlM2RAiPZrLcDu2cwZ4OF4HbAGh1DjnnUCNqgk8SAYlARM_7O-kuBi9td_fza8nM&__tn__=kK-R" rel="noopener noreferrer" target="_blank">KTIRIO Τεχνικό Περιοδικό </a>
              - Αναδιαμόρφωση κατοικίας στον οικισμό Πλάκα Ελούντας - Μάρτιος 2020
            </div>
            <div className="publications-items">
              <span className="p-title">Archisearch </span>
              - House reconstruction in Plaka of Elounda – Σεπτέμβριος 2019
            </div>
            <div className="publications-items">
              <span className="p-title">Domes 2019 </span>
              - Επετηρίδα Ελληνικής Αρχιτεκτονικής 2019 - Αναδιαμόρφωση κατοικίας στον οικισμό Πλάκα Ελούντας – Ιούλιος 2019
            </div>
          </div>
          <div className="publications-arrow-wrap">
            {downArrow && <DownOutlined className="publications-arrow" />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Publications;
