import React, { useEffect } from 'react';
import { navigate, Link } from '@reach/router';

import './manageprojectpage.css';

const ManageProjectPage = () => {
  useEffect(() => {
    if (!document.cookie) {
      navigate('/login/');
    }
  });
  return (
    <>
      <div className="manage">
        <div className="manage-container">
          <div className="manage-container-project">
            <Link to="/editprojects">
              <div className="manage-items">Edit Project</div>
            </Link>
            <Link to="/newproject">
              <div className="manage-items">New Project</div>
            </Link>
          </div>
          <div className="manage-container-homepage">
            <Link to="/edithomepage">
              <div className="manage-items">Edit Homepage</div>
            </Link>
            <Link to="/newhomepage">
              <div className="manage-items">New Homepage Image</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageProjectPage;
