import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import {
  Spin, Form, Input, Tooltip, Button,
} from 'antd';
import { navigate } from '@reach/router';
import { UserLogin } from '../utils';
import LoginContext from '../loginContext';

import './loginpage.css';

function LoginPage() {
  const [loginState, setLoginState] = React.useContext(LoginContext);
  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    const userlogin = await UserLogin(values.username, values.password);
    const { token } = userlogin;
    if (userlogin) {
      console.log('user login', token);
      setLoginState(true);
      navigate('/manage/');

      // await this.props.setUser(values.username, token);
      // await this.props.inputNextPage(this.context.currentPage);
    }
  };

  return (
    <div>
      <Spin className="spin" spinning={false}>
        <div className="login-form">
          <Form initialValues={{ remember: true }} onFinish={onFinish}>
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input your username!', whitespace: true }]}
              label={(
                <span>
                  Username&nbsp;
                  <Tooltip title="Your name" />
                </span>
                    )}
            >

              <Input style={{ width: 200 }} placeholder="Username" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!', whitespace: true }]}
              label={(
                <span>
                  Password&nbsp;
                  <Tooltip title="Password" />
                </span>
                    )}
            >

              <Input.Password style={{ width: 200 }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
}

export default LoginPage;
