import React, { useEffect, useRef, useState } from 'react';
import { navigate } from '@reach/router';
import 'antd/dist/antd.css';
import {
  Spin, Form, Input, Tooltip, Select, Button, Checkbox, Upload, Modal,
} from 'antd';
import Grid from '@material-ui/core/Grid';
import { SubmitHomeProject, DeleteHomeProject } from '../utils';
import LoginContext from '../loginContext';

import './newprojectpage.css';

function EditProjectPage({ location, imageId }) {
  useEffect(() => {
    if (!document.cookie) {
      navigate('/login/');
    }
  });
  const [project, setProject] = React.useState(null);
  useEffect(() => {
    async function getProject() {
      setProject(location.state.image);
    }
    if (document.cookie) {
      getProject();
    }
  }, []);
  // const [loginState] = React.useContext(LoginContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const formRef = useRef();
  const { Option } = Select;
  const { TextArea } = Input;
  const onFinish = async (values) => {
    const allValues = values;
    const submit = await SubmitHomeProject(allValues, true);
    if (submit) {
      navigate('/edithomepage');
    }
  };
  const deleteProject = async () => {
    const deleted = await DeleteHomeProject(project.id, project.name);
    if (deleted) {
      navigate('/edithomepage');
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    deleteProject();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Spin className="spin" spinning={false}>
        <div className="project-form">
          <Grid className="formgrid" container>
            <Grid item xs={6}>
              {project && (
              <Form
                initialValues={{
                  name: project.name,
                  description: project.description,
                  description_el: project.description_el,
                  remember: true,
                }}
                onFinish={onFinish}
                ref={formRef}
              >
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Please input project name', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Name" disabled />
                </Form.Item>
                <Form.Item
                  name="description"
                  rules={[{ message: 'Please input project description', whitespace: true }]}
                >
                  <TextArea rows={4} style={{ width: 200 }} placeholder="Description" />
                </Form.Item>
                <Form.Item
                  name="description_el"
                  rules={[{ message: 'Please input project description', whitespace: true }]}
                >
                  <TextArea rows={4} style={{ width: 200 }} placeholder="Περιγραφή" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">Update</Button>
                </Form.Item>
              </Form>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button type="primary" onClick={showModal}>Delete Project</Button>
              <Modal title="Delete Project" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Are you sure you want to delete this project?</p>
              </Modal>
            </Grid>
          </Grid>
        </div>
      </Spin>
    </div>
  );
}

export default EditProjectPage;
