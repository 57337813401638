import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import './footer.css';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logos/entopos-black.svg';
import logoHover from '../assets/logos/entopos-hover.svg';

function Footer() {
  const { i18n } = useTranslation();
  const grRef = useRef();
  const enRef = useRef();
  const footerRef = useRef();
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    if (lang === 'el') {
      grRef.current.style.color = '#242424';
      enRef.current.style.color = '#d3d3d3';
    } else {
      grRef.current.style.color = '#d3d3d3';
      enRef.current.style.color = '#242424';
    }
  };

  useEffect(() => {
    if (i18n.language === 'el') {
      grRef.current.style.color = '#242424';
      enRef.current.style.color = '#d3d3d3';
    } else {
      grRef.current.style.color = '#d3d3d3';
      enRef.current.style.color = '#242424';
    }
  });

  useEffect(() => {
    footerRef.current.addEventListener('wheel', (event) => event.preventDefault());
    footerRef.current.addEventListener('touchmove', (e) => {
      e.preventDefault();
    }, { passive: false });
  }, []);
  return (
    <>
      <Grid className="footer" container ref={footerRef}>
        <Grid item xs={4}>
          <div className="email" />
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <Link to="/">
              <img
                src={logo}
                alt="ENTOPOS"
                height="11"
                onMouseOver={(e) => { e.currentTarget.src = logoHover; }}
                onFocus={(e) => { e.currentTarget.src = logoHover; }}
                onMouseOut={(e) => { e.currentTarget.src = logo; }}
                onBlur={(e) => { e.currentTarget.src = logo; }}
              />
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="flex-end">
            <div className="language">
              <span className="lang" ref={grRef} onClick={() => changeLang('el')}>ελ </span>
              /
              <span className="lang" ref={enRef} onClick={() => changeLang('en')}> en</span>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;
