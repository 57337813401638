import React, { useEffect, useRef, useState } from 'react';
import { navigate } from '@reach/router';
import 'antd/dist/antd.css';
import {
  Spin, Form, Input, Tooltip, Select, Button, Checkbox, Upload, Modal,
} from 'antd';
import Grid from '@material-ui/core/Grid';
import { SubmitProject, DeleteProject } from '../utils';
import LoginContext from '../loginContext';

import './newprojectpage.css';

function EditProjectPage({ location, projectId }) {
  useEffect(() => {
    if (!document.cookie) {
      navigate('/login/');
    }
  });
  const [project, setProject] = React.useState(null);
  useEffect(() => {
    async function getProject() {
      setProject(location.state.project);
    }
    if (document.cookie) {
      getProject();
    }
  }, []);
  // const [loginState] = React.useContext(LoginContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const formRef = useRef();
  const { Option } = Select;
  const { TextArea } = Input;
  const onFinish = async (values) => {
    const allValues = values;
    const submit = await SubmitProject(allValues, true);
    if (submit) {
      navigate('/editprojects');
    }
  };
  const deleteProject = async () => {
    const deleted = await DeleteProject(project.id, project.name);
    if (deleted) {
      navigate('/editprojects');
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    deleteProject();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Spin className="spin" spinning={false}>
        <div className="project-form">
          <Grid className="formgrid" container>
            <Grid item xs={6}>
              {project && (
              <Form
                initialValues={{
                  name: project.name,
                  title: project.title,
                  title_el: project.title_el,
                  description: project.description,
                  description_el: project.description_el,
                  analysis: project.analysis,
                  analysis_el: project.analysis_el,
                  location: project.location,
                  location_el: project.location_el,
                  year: project.year,
                  size: project.size,
                  design_team: project.design_team,
                  design_team_el: project.design_team_el,
                  construction_team: project.construction_team,
                  construction_team_el: project.construction_team_el,
                  photographer: project.photographer,
                  photographer_el: project.photographer_el,
                  category: project.category,
                  status: project.status,
                  is_active: project.is_active,
                  remember: true,
                }}
                onFinish={onFinish}
                ref={formRef}
              >
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Please input project name', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Name" disabled />
                </Form.Item>
                <Form.Item
                  name="title"
                  rules={[{ required: true, message: 'Please input project title', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Title" />
                </Form.Item>
                <Form.Item
                  name="title_el"
                  rules={[{ required: true, message: 'Please input project title', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Τίτλος" />
                </Form.Item>
                <Form.Item
                  style={{ display: 'none' }}
                  name="description"
                  rules={[{ message: 'Please input project description', whitespace: true }]}
                >
                  <TextArea defaultValue="test" rows={4} style={{ width: 200 }} placeholder="Description" />
                </Form.Item>
                <Form.Item
                  name="description_el"
                  style={{ display: 'none' }}
                  rules={[{ message: 'Please input project description', whitespace: true }]}
                >
                  <TextArea defaultValue="test" rows={4} style={{ width: 200 }} placeholder="Περιγραφή" />
                </Form.Item>
                <Form.Item
                  name="analysis"
                  rules={[{ required: true, message: 'Please input project analysis', whitespace: true }]}
                >
                  <TextArea rows={4} style={{ width: 200 }} placeholder="Analysis" />
                </Form.Item>
                <Form.Item
                  name="analysis_el"
                  rules={[{ required: true, message: 'Please input project analysis', whitespace: true }]}
                >
                  <TextArea rows={4} style={{ width: 200 }} placeholder="Ανάλυση" />
                </Form.Item>
                <Form.Item
                  name="location"
                  rules={[{ required: true, message: 'Please input project location', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Location" />
                </Form.Item>
                <Form.Item
                  name="location_el"
                  rules={[{ required: true, message: 'Please input project location', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Τοποθεσία" />
                </Form.Item>
                <Form.Item
                  name="year"
                  rules={[{ required: true, message: 'Please input project year', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Year" />
                </Form.Item>
                <Form.Item
                  name="size"
                  rules={[{ required: true, message: 'Please input project size', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Size" />
                </Form.Item>
                <Form.Item
                  name="design_team"
                  rules={[{ required: true, message: 'Please input project design team', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Design Team" />
                </Form.Item>
                <Form.Item
                  name="design_team_el"
                  rules={[{ required: true, message: 'Please input project design team', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Ομάδα Σχεδιασμού" />
                </Form.Item>
                <Form.Item
                  name="construction_team"
                  rules={[{ required: true, message: 'Please input project construction team', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Construction Team" />
                </Form.Item>
                <Form.Item
                  name="construction_team_el"
                  rules={[{ required: true, message: 'Please input project construction team', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Ομάδα Κατασκευής" />
                </Form.Item>
                <Form.Item
                  name="photographer"
                  rules={[{ required: true, message: 'Please input project photographer', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Photographer" />
                </Form.Item>
                <Form.Item
                  name="photographer_el"
                  rules={[{ required: true, message: 'Please input project photographer', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Φωτογράφος" />
                </Form.Item>
                <Form.Item
                  name="category"
                  rules={[{ required: true, message: 'Please input project category', whitespace: true }]}
                >
                  <Select style={{ width: 200 }} placeholder="Category">
                    <Option value="Residential">Residential</Option>
                    <Option value="Commercial">Commercial</Option>
                    <Option value="Accommodation">Accommodation</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="status"
                  rules={[{ required: true, message: 'Please input project status!', whitespace: true }]}
                >
                  <Select style={{ width: 200 }} placeholder="Status">
                    <Option value="completed">Completed</Option>
                    <Option value="ongoing">Ongoing</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  valuePropName="checked"
                  name="is_active"
                >
                  <Checkbox>Active</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">Update</Button>
                </Form.Item>
              </Form>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button type="primary" onClick={showModal}>Delete Project</Button>
              <Modal title="Delete Project" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Are you sure you want to delete this project?</p>
              </Modal>
            </Grid>
          </Grid>
        </div>
      </Spin>
    </div>
  );
}

export default EditProjectPage;
