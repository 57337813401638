import { notification } from 'antd';
import { BACKEND_URL } from './config';
import 'antd/dist/antd.css';
import i18n from './i18n';

async function UserLogin(username, password) {
  const formData = new FormData();
  const status = false;

  formData.append('username', username);
  formData.append('password', password);

  const options = {
    method: 'POST',
    body: formData,
    credentials: 'include',
  };

  const res = await fetch(`${BACKEND_URL}/login`, options)
    .then((result) => {
      if (result.status === 200) {
        console.log('Login success');
        return result.json();
      } if (result.status !== 200) {
        notification.error({
          message: 'Failed to login.',
          description: `error: ${result.status}`,
        });
        return false;
      }
    })
    .then((data) => data)
    .catch((e) => {
      notification.error({
        message: 'Failed to login.',
        description: `error: ${e}`,
      });
      return false;
    });
  return res;
}

async function UserLogout() {
  const options = {
    method: 'GET',
    credentials: 'include',
  };

  const res = await fetch(`${BACKEND_URL}/logout`, options)
    .then((res) => {
      if (res.status === 200) {
        console.log('Logout success');
        return true;
      } if (res.status !== 200) {
        notification.error({
          message: 'Failed to logout.',
          description: `error: ${res.status}`,
        });
        return false;
      }
    }).catch((e) => {
      notification.error({
        message: 'Failed to logout.',
        description: `error: ${e}`,
      });
      return false;
    });
  return res;
}

async function SubmitProject(values, update = false) {
  console.log(values);
  const project = {
    name: values.name,
    title: values.title,
    title_el: values.title_el,
    description: values.description,
    description_el: values.description_el,
    analysis: values.analysis,
    analysis_el: values.analysis_el,
    location: values.location,
    location_el: values.location_el,
    year: values.year,
    design_team: values.design_team,
    design_team_el: values.design_team_el,
    construction_team: values.construction_team,
    construction_team_el: values.construction_team_el,
    photographer: values.photographer,
    photographer_el: values.photographer_el,
    category: values.category,
    status: values.status,
    size: values.size,
    is_active: values.is_active ? values.is_active : false,
    images: values.images,
    thumbnail: values.thumbnail,
  };

  let reqMethod = 'POST';
  if (update) {
    reqMethod = 'PUT';
  }

  const options = {
    method: reqMethod,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(project),
    credentials: 'include',
  };

  const res = await fetch(`${BACKEND_URL}/projects`, options)
    .then((result) => {
      if (!result.ok) throw result;
      notification.success({
        message: 'Project has been saved.',
      });
      return result.json;
    })
    .then((data) => data)
    .catch((error) => {
      error.json().then((body) => {
        notification.error({
          message: 'Failed to save project.',
          description: `${body.error}`,
        });
      });
      return false;
    });
  return res;
}

async function DeleteProject(id, name) {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name }),
    credentials: 'include',
  };

  const res = await fetch(`${BACKEND_URL}/projects/${id}`, options)
    .then((result) => {
      if (!result.ok) throw result;
      notification.success({
        message: 'Project has been deleted.',
      });
      return result.json;
    })
    .then((data) => data)
    .catch((error) => {
      error.json().then((body) => {
        notification.error({
          message: 'Failed to delete project.',
          description: `${body.error}`,
        });
      });
      return false;
    });
  return res;
}

async function GetProjects(all = false) {
  const options = {
    method: 'GET',
    credentials: 'include',
  };

  let projectsPath = 'projects';
  if (all) {
    projectsPath = 'allprojects';
  }
  console.log('all', all, projectsPath);
  const res = await fetch(`${BACKEND_URL}/${projectsPath}`, options)
    .then((result) => {
      if (!result.ok) throw result;
      return result;
    })
    .then((data) => data.json())

    .catch((error) => {
      error.json().then((body) => {
        notification.error({
          message: 'Failed to save project.',
          description: `${body.error}`,
        });
      });
      return false;
    });
  return res;
}

async function GetHomepageProjects() {
  const options = {
    method: 'GET',
    credentials: 'include',
  };

  const projectsPath = 'homepage_projects';
  const res = await fetch(`${BACKEND_URL}/${projectsPath}`, options)
    .then((result) => {
      if (!result.ok) throw result;
      return result;
    })
    .then((data) => data.json())

    .catch((error) => {
      error.json().then((body) => {
        notification.error({
          message: 'Failed to save project.',
          description: `${body.error}`,
        });
      });
      return false;
    });
  return res;
}

async function GetProject(name) {
  const options = {
    method: 'GET',
    credentials: 'include',
  };

  const res = await fetch(`${BACKEND_URL}/projects/${name}`, options)
    .then((result) => {
      if (!result.ok) throw result;
      return result;
    })
    .then((data) => data.json())

    .catch((error) => {
      error.json().then((body) => {
        notification.error({
          message: 'Failed to save project.',
          description: `${body.error}`,
        });
      });
      return false;
    });
  return res;
}

async function GetHomepageProject(name) {
  const options = {
    method: 'GET',
    credentials: 'include',
  };

  const res = await fetch(`${BACKEND_URL}/homepage_projects/${name}`, options)
    .then((result) => {
      if (!result.ok) throw result;
      return result;
    })
    .then((data) => data.json())

    .catch((error) => {
      error.json().then((body) => {
        notification.error({
          message: 'Failed to save project.',
          description: `${body.error}`,
        });
      });
      return false;
    });
  return res;
}

function GetImages(id, stImage, amount) {
  const options = {
    method: 'GET',
    credentials: 'include',
  };
  return fetch(`${BACKEND_URL}/get_photos/${id}/${stImage}/${amount}`, options)
    .then((data) => data.json())
    .then((res) => res);
}

function GetNumOfImages(id) {
  const options = {
    method: 'GET',
    credentials: 'include',
  };
  return fetch(`${BACKEND_URL}/get_num_photos/${id}`, options)
    .then((data) => data.json())
    .then((res) => res);
}

async function SubmitHomeProject(values, update = false) {
  const project = {
    name: values.name,
    description: values.description,
    description_el: values.description_el,
    image: values.image,
    image_mob: values.image_mob,
  };

  let reqMethod = 'POST';
  if (update) {
    reqMethod = 'PUT';
  }

  const options = {
    method: reqMethod,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(project),
    credentials: 'include',
  };

  const res = await fetch(`${BACKEND_URL}/homepage_projects`, options)
    .then((result) => {
      if (!result.ok) throw result;
      notification.success({
        message: 'Homepage Project has been saved.',
      });
      return result.json;
    })
    .then((data) => data)
    .catch((error) => {
      error.json().then((body) => {
        notification.error({
          message: 'Failed to save homepage project.',
          description: `${body.error}`,
        });
      });
      return false;
    });
  return res;
}

async function DeleteHomeProject(id, name) {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name }),
    credentials: 'include',
  };

  const res = await fetch(`${BACKEND_URL}/homepage_projects/${id}`, options)
    .then((result) => {
      if (!result.ok) throw result;
      notification.success({
        message: 'Project has been deleted.',
      });
      return result.json;
    })
    .then((data) => data)
    .catch((error) => {
      error.json().then((body) => {
        notification.error({
          message: 'Failed to delete project.',
          description: `${body.error}`,
        });
      });
      return false;
    });
  return res;
}

function AddLanguageResources(project, index) {
  const analysisParagraphs = project.analysis.replace(/[\r\n]{1,}/g, '\n').split('\n');
  const analysisParagraphs_el = project.analysis_el.replace(/[\r\n]{2,}/g, '\n').split('\n');

  analysisParagraphs.forEach((analysis, i) => {
    i18n.addResource('en', 'translation', `analysis${index}-${i}`, analysis);
  });

  analysisParagraphs_el.forEach((analysis, i) => {
    i18n.addResource('el', 'translation', `analysis${index}-${i}`, analysis);
  });

  i18n.addResource('en', 'translation', `title${index}`, project.title);
  i18n.addResource('el', 'translation', `title${index}`, project.title_el);
  i18n.addResource('en', 'translation', `description${index}`, project.description);
  i18n.addResource('el', 'translation', `description${index}`, project.description_el);
  i18n.addResource('en', 'translation', `location${index}`, project.location);
  i18n.addResource('el', 'translation', `location${index}`, project.location_el);
  i18n.addResource('en', 'translation', `design_team${index}`, project.design_team);
  i18n.addResource('el', 'translation', `design_team${index}`, project.design_team_el);
  i18n.addResource('en', 'translation', `construction_team${index}`, project.construction_team);
  i18n.addResource('el', 'translation', `construction_team${index}`, project.construction_team_el);
  i18n.addResource('en', 'translation', `photographer${index}`, project.photographer);
  i18n.addResource('el', 'translation', `photographer${index}`, project.photographer_el);
  i18n.addResource('en', 'translation', `category${index}`, project.category);
  if (project.category === 'Residential') {
    i18n.addResource('el', 'translation', `category${index}`, 'Κατοικία');
  } else if (project.category === 'Commercial') {
    i18n.addResource('el', 'translation', `category${index}`, 'Κατάστημα');
  } else {
    i18n.addResource('el', 'translation', `category${index}`, 'Φιλοξενία');
  }
  if (project.status === 'completed') {
    i18n.addResource('en', 'translation', `status${index}`, 'Completed');
    i18n.addResource('el', 'translation', `status${index}`, 'Ολοκληρωμένο');
  } else {
    i18n.addResource('en', 'translation', `status${index}`, 'Ongoing');
    i18n.addResource('el', 'translation', `status${index}`, 'Σε εξέλιξη');
  }
}

function AddLanguageResourcesHomepage(project, index) {
  i18n.addResource('en', 'translation', `img_desc${index}`, project.description);
  i18n.addResource('el', 'translation', `img_desc${index}`, project.description_el);
}

const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export {
  UserLogin, UserLogout, SubmitProject, SubmitHomeProject, DeleteProject, DeleteHomeProject, GetProjects, GetHomepageProjects, GetHomepageProject, GetImages, GetNumOfImages, GetProject, AddLanguageResources, AddLanguageResourcesHomepage, sleep,
};
