import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@reach/router';
import './menu.css';

const Menu = ({ closeMenu }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const grRef = useRef();
  const enRef = useRef();
  const homeRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();
  const studioRef = useRef();
  // const location = useLocation();
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    if (lang === 'el') {
      grRef.current.style.color = '#242424';
      enRef.current.style.color = '#d3d3d3';
    } else {
      grRef.current.style.color = '#d3d3d3';
      enRef.current.style.color = '#242424';
    }
  };

  useEffect(() => {
    if (i18n.language === 'el') {
      grRef.current.style.color = '#242424';
      enRef.current.style.color = '#d3d3d3';
    } else {
      grRef.current.style.color = '#d3d3d3';
      enRef.current.style.color = '#242424';
    }
  });

  // useEffect(() => {
  //   if (location.pathname === '/') {
  //     homeRef.current.classList.add('active');
  //     projectsRef.current.classList.remove('active');
  //     contactRef.current.classList.remove('active');
  //   } else if (location.pathname === '/grid') {
  //     projectsRef.current.classList.add('active');
  //     homeRef.current.classList.remove('active');
  //     contactRef.current.classList.remove('active');
  //   }
  // }, []);
  return (
    <>
      <div className="menu">
        <div className="menu-container">
          <Link to="/" onClick={closeMenu}>
            <div ref={homeRef} className="menu-items">{t('home')}</div>
          </Link>
          <Link to="/projects" onClick={closeMenu}>
            <div ref={projectsRef} className="menu-items">{t('projects')}</div>
          </Link>
          <Link to="/publications" onClick={closeMenu}>
            <div className="menu-items">{t('publications')}</div>
          </Link>
          <Link to="/contact" onClick={closeMenu}>
            <div ref={contactRef} className="menu-items">{t('contact')}</div>
          </Link>
          <Link to="/studio" onClick={closeMenu}>
            <div ref={studioRef} className="menu-items">{t('studio')}</div>
          </Link>
          <div className="menu-language">
            <span className="lang" ref={grRef} onClick={() => changeLang('el')}>ελ </span>
            /
            <span className="lang" ref={enRef} onClick={() => changeLang('en')}> en</span>
          </div>
          <div className="menu-social">
            <a href="https://www.facebook.com/entopos" target="_blank" className="facebook">facebook</a>

            <a href="https://www.instagram.com/en_topos/" target="_blank" className="instagram">instagram</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
