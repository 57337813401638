import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import {
  Spin, Form, Input, Tooltip, Select, Button, Checkbox, Upload, Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import Grid from '@material-ui/core/Grid';
import { SubmitHomeProject } from '../utils';
import LoginContext from '../loginContext';

import './newhomepage.css';

const loadingIcon = <LoadingOutlined style={{ fontSize: 42, color: '#232323', marginTop: 35 }} spin />;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function getBase64Thumb(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function NewHomePage() {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loginState] = React.useContext(LoginContext);
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState(null);
  const [imageMob, setImageMob] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const formRef = useRef();
  useEffect(() => {
    if (!document.cookie) {
      navigate('/login/');
    }
  });
  const { Option } = Select;
  const { TextArea } = Input;
  const onFinish = async (values) => {
    const allValues = values;
    const images = [];

    const getImages = async (item) => { // a function that returns a promise
      const img = await getBase64(item.originFileObj); // eslint-disable-line no-param-reassign
      const imgId = item.uid;
      images.push({ id: imgId, src: img });
      return Promise.resolve('ok');
    };

    const getData = async () => Promise.all(fileList.map((item) => getImages(item)));

    getData().then(async () => {
      allValues.image = image;
      allValues.image_mob = imageMob;
      setSubmitLoading(true);
      const submit = await SubmitHomeProject(allValues);
      setSubmitLoading(false);
      if (submit) {
        formRef.current.resetFields();
        setFileList([]);
        setImage(null);
        setImageMob(null);
      }
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewVisible(false);

  const handleChangeThumb = (info) => {
    getBase64Thumb(info.file, (imageUrl) => setImage(imageUrl));
  };
  const handleChangeImageMob = (info) => {
    getBase64Thumb(info.file, (imageUrl) => setImageMob(imageUrl));
  };

  return (
    <div>
      <Spin spinning={submitLoading} indicator={loadingIcon}>
        <div className="project-form">
          <Grid className="formgrid" container>
            <Grid item xs={6}>
              <Form initialValues={{ remember: true }} onFinish={onFinish} ref={formRef}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Please input project name', whitespace: true }]}
                >
                  <Input style={{ width: 200 }} placeholder="Name" />
                </Form.Item>
                <Form.Item
                  name="description"
                >
                  <TextArea rows={4} style={{ width: 200 }} placeholder="Description" />
                </Form.Item>
                <Form.Item
                  name="description_el"
                >
                  <TextArea rows={4} style={{ width: 200 }} placeholder="Περιγραφή" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
              </Form>
            </Grid>
            <Grid item xs={6}>
              Image
              <Upload
                listType="picture-card"
                showUploadList={false}
                onChange={handleChangeThumb}
                beforeUpload={() => false}
              >
                {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}

              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
              Image Mobile
              <Upload
                listType="picture-card"
                showUploadList={false}
                onChange={handleChangeImageMob}
                beforeUpload={() => false}
              >
                {imageMob ? <img src={imageMob} alt="avatar" style={{ width: '100%' }} /> : uploadButton}

              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </Grid>
          </Grid>
        </div>
      </Spin>
    </div>
  );
}

export default NewHomePage;
