import React, { useEffect, useRef, useState } from 'react';
import {
  Col, Row,
} from 'antd';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import mapImg from '../assets/images/ENTOPOS WEB-FINAL.jpg';
import './contactpage.css';

function ContactPage() {
  const { t } = useTranslation();
  const [loadedImage, setLoadedImage] = useState(false);
  return (
    <>
      <div className="contactpage">
        <div className="contact">
          <BrowserView>
            <Row className="contact-row">
              <Col span={12}>
                <img
                  className="map-img"
                  alt="ENTOPOS"
                  src={mapImg}
                />
              </Col>
              <Col span={12}>
                <div className="contact-wrap">
                  <div className="contact-details">
                    <Row style={{ marginBottom: 30 }}>
                      Entopos
                    </Row>
                    <Row>
                      <Col span={8}>
                        {t('city')}
                      </Col>
                      <Col span={8}>
                        {t('street')}
                      </Col>
                      <Col span={8}>
                        <Row style={{ float: 'right' }}>
                          {t('county')}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        {t('postcode')}
                      </Col>
                      <Col span={12}>
                        <Row style={{ float: 'right' }}>
                          T +(00)30 2810 335079
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <a href="https://www.facebook.com/entopos" target="_blank" className="contact-facebook">facebook</a>
                      </Col>
                      <Col span={12}>
                        <Row style={{ float: 'right' }}>
                          <a href="https://www.instagram.com/en_topos/" target="_blank" className="contact-instagram">instagram</a>
                        </Row>
                      </Col>

                    </Row>
                    <Row>
                      info@entopos.com
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </BrowserView>
          <MobileView>
            <Row className="contact-row">
              <div className="mob-contact-wrap">
                <div className="mob-contact-details">
                  <Row style={{ marginBottom: 30 }}>
                    Entopos
                  </Row>
                  <Row>
                    <Col span={8}>
                      {t('city')}
                    </Col>
                    <Col span={8}>
                      {t('street')}
                    </Col>
                    <Col span={8}>
                      <Row style={{ float: 'right' }}>
                        {t('county')}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      {t('postcode')}
                    </Col>
                    <Col span={12}>
                      <Row style={{ float: 'right' }}>
                        T +(00)30 2810 335079
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <a href="https://www.facebook.com/entopos" target="_blank" className="contact-facebook">facebook</a>
                    </Col>
                    <Col span={12}>
                      <Row style={{ float: 'right' }}>
                        <a href="https://www.instagram.com/en_topos/" target="_blank" className="contact-instagram">instagram</a>
                      </Row>
                    </Col>

                  </Row>
                  <Row>
                    info@entopos.com
                  </Row>
                </div>
              </div>
              <div className="map-wrap">
                <img
                  className="mob-map-img"
                  alt="ENTOPOS"
                  src={mapImg}
                />
              </div>
            </Row>
          </MobileView>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
