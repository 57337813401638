import React, { useState, useRef, useEffect } from 'react';
import { Link } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import logo from '../assets/logos/logo-black.svg';
import logoHover from '../assets/logos/logo-hover.svg';
import menuIcon from '../assets/icons/menu.svg';
import xIcon from '../assets/icons/x.svg';
import gridIcon from '../assets/icons/grid.svg';
import Menu from './menu';
import './header.css';

function Header() {
  const [isMenuIcon, setMenuIcon] = useState(false);
  const menuRef = useRef();
  const headerRef = useRef();

  const toggleClose = () => {
    setMenuIcon(!isMenuIcon);
    // e.currentTarget.classList.add('closing');
    menuRef.current.classList.add('closing');
  };

  const toggleRotate = (e) => {
    e.currentTarget.classList.add('rotate');
    if (isMenuIcon) {
      toggleClose();
    }
  };

  const toggleHome = () => {
    if (isMenuIcon) {
      toggleClose();
    }
  };
  const removeRotate = (e) => {
    e.currentTarget.classList.remove('rotate');
  };

  const changeIcon = (e) => {
    if (e.currentTarget.classList.contains('closing')) {
      e.currentTarget.classList.remove('closing');

      if (isMenuIcon) {
        e.currentTarget.src = xIcon;
      } else {
        e.currentTarget.src = menuIcon;
      }
      e.currentTarget.classList.add('opening');
    } else if (e.currentTarget.classList.contains('opening')) {
      e.currentTarget.classList.remove('opening');
    }
  };

  useEffect(() => {
    isMenuIcon && (document.body.style.overflow = 'hidden');
    !isMenuIcon && (document.body.style.overflow = 'unset');
  }, [isMenuIcon]);

  useEffect(() => {
    headerRef.current.addEventListener('wheel', (event) => event.preventDefault());
    headerRef.current.addEventListener('touchmove', (e) => {
      e.preventDefault();
    }, { passive: false });
  }, []);

  return (
    <>
      <Grid className="header" container ref={headerRef}>
        <Grid item xs={4}>
          <img
            src={menuIcon}
            className="menu-img"
            ref={menuRef}
            alt="menu"
            onClick={() => toggleClose()}
            onAnimationEnd={(e) => changeIcon(e)}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <Link to="/">
              <img
                src={logo}
                alt="ENTOPOS"
                className="homepage-img"
                onMouseOver={(e) => { e.currentTarget.src = logoHover; }}
                onFocus={(e) => { e.currentTarget.src = logoHover; }}
                onMouseOut={(e) => { e.currentTarget.src = logo; }}
                onBlur={(e) => { e.currentTarget.src = logo; }}
                onClick={() => { toggleHome(); }}
              />
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="flex-end">
            <Link to="/projects">
              <img
                src={gridIcon}
                className="grid-img"
                alt="menu"
                onClick={(e) => toggleRotate(e)}
                onAnimationEnd={(e) => removeRotate(e)}
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      { isMenuIcon && <Menu closeMenu={() => toggleClose()} />}
    </>
  );
}

export default Header;
