import React, { useEffect, useRef, useState } from 'react';
import {
  Col, Row,
} from 'antd';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import mapImg from '../assets/images/ENTOPOS WEB-FINAL.jpg';
import './studiopage.css';
import { RightOutlined } from '@ant-design/icons';

function StudioPage() {
  const { t } = useTranslation();
  const [loadedImage, setLoadedImage] = useState(false);
  return (
    <>
      <div className="studiopage">
        <BrowserView>
          <div className="studio">
            <Row className="studio-row">
              <Col span={12}>
                <div className="studio-desc">
                  <div className="studio-desc-title">
                    {t('studio_desc_title')}
                  </div>
                  {t('studio_desc')}
                </div>
              </Col>
              <Col span={12}>
                <div className="studio-team">
                  <div className="studio-team-title">
                    {t('studio_team_title')}
                  </div>
                  <div className="studio-emp">
                    {t('yannadakis')}
                    <div className="studio-emp-desc">
                      Founder, Managing Director, Architect Eng.
                    </div>
                  </div>
                  <div className="studio-emp">
                    {t('karfi')}
                    <div className="studio-emp-desc">
                      Senior Project Architect, Architect Eng.
                    </div>
                  </div>
                  <div className="studio-emp">
                    {t('andreopoulou')}
                    <div className="studio-emp-desc">
                      Project Architect, Architect Eng.
                    </div>
                  </div>
                  <div className="studio-emp">
                    {t('klinaki')}
                    <div className="studio-emp-desc">
                      Project Architect, Architect Eng.
                    </div>
                  </div>
                  <div className="studio-emp">
                    {t('kouros')}
                    <div className="studio-emp-desc">
                      Art Direction, Architect Eng.
                    </div>
                  </div>
                  <div className="studio-emp">
                    {t('lemonis')}
                    <div className="studio-emp-desc">
                      Site Coordinator, Electrical Eng,
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </BrowserView>
        <MobileView>
          <div className="studio-mob">
            <Row className="studio-row-mob">
              <Col style={{ width: '43%' }}>
                <div className="studio-desc-mob">
                  <div className="studio-desc-title-mob">
                    {t('studio_desc_title')}
                  </div>
                  {t('studio_desc')}
                </div>
              </Col>
              <Col style={{ width: '4%' }} className="studio-arrow-col">
                <RightOutlined className="studio-arrow" />
              </Col>
              <Col style={{ width: '50%' }}>
                <div className="studio-team-mob">
                  <div className="studio-team-title-mob">
                    {t('studio_team_title')}
                  </div>
                  <div className="studio-emp-mob">
                    {t('yannadakis')}
                    <div className="studio-emp-desc-mob">
                      Founder, Managing Director, Architect Eng.
                    </div>
                  </div>
                  <div className="studio-emp-mob">
                    {t('karfi')}
                    <div className="studio-emp-desc-mob">
                      Senior Project Architect, Architect Eng.
                    </div>
                  </div>
                  <div className="studio-emp-mob">
                    {t('andreopoulou')}
                    <div className="studio-emp-desc-mob">
                      Project Architect, Architect Eng.
                    </div>
                  </div>
                  <div className="studio-emp-mob">
                    {t('klinaki')}
                    <div className="studio-emp-desc-mob">
                      Project Architect, Architect Eng.
                    </div>
                  </div>
                  <div className="studio-emp-mob">
                    {t('kouros')}
                    <div className="studio-emp-desc-mob">
                      Art Direction, Architect Eng.
                    </div>
                  </div>
                  <div className="studio-emp-mob">
                    {t('lemonis')}
                    <div className="studio-emp-desc-mob">
                      Site Coordinator, Electrical Eng,
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </MobileView>
      </div>
    </>
  );
}

export default StudioPage;
