import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import {
  Spin, Col, Row,
  Image,
} from 'antd';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';

import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import smoothscroll from 'smoothscroll-polyfill';
import * as FastClick from 'fastclick';
import {
  GetImages, GetNumOfImages, GetProject, AddLanguageResources, sleep,
} from '../utils';

import './projectpage.css';

const loadingIcon = <LoadingOutlined style={{ fontSize: 42, color: '#232323', marginTop: 35 }} spin />;

const ProjectPage = ({ location, projectId }) => {
  const { t } = useTranslation();
  const [images, setImages] = useState('');
  const [scrollPos, setScrollPos] = useState(null);
  const projectRef = useRef(null);
  const projectpageRef = useRef(null);
  const analysisCnt = useRef(null);
  const startY = useRef(null);
  const prevStartY = useRef(null);
  const delta = useRef(null);
  const startTime = useRef(null);
  const endTime = useRef(null);
  const scrollRef = useRef(null);
  const multiplier = useRef(1);
  const indexRef = useRef(null);
  const startTouchEnd = useRef(null);
  const scrolling = useRef(false);
  const [newImagesLoading, setNewImagesLoading] = useState(false);
  const touchError = 5;
  const i_speedLimit = 1.2;
  const i_moveThreshold = 100;
  const i_offsetThreshold = 30;
  const i_acceleration = 0.5;
  const i_accelerationT = 300;
  let acc = null;
  const onWheel = (e) => {
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;
    setScrollPos(containerScrollPosition);

    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
    });
  };

  /* touchend – calculate touchtime by comparing the current time to the last touchmove time,
   calculate offset by taking the acceleration to the power of 2, times the window height or the
   scrolling element’s width, depending on direction. Then, scroll the element for an extra
   amount of pixels (offset), using quartic ‘out’ easing (which seems to best match the native
   momentum scrolling curve), for a pre-set duration. */

  const normalize = (enteredValue, minEntry = 10, maxEntry = 3000, normalizedMin = 0, normalizedMax = 1) => {
    const mx = (enteredValue - minEntry) / (maxEntry - minEntry);

    const preshiftNormalized = mx * (normalizedMax - normalizedMin);

    const shiftedNormalized = preshiftNormalized + normalizedMin;

    return shiftedNormalized;
  };

  const onTouchStart = (e) => {
    startTouchEnd.current = false;
    // e.preventDefault();
    startY.current = e.touches[0].clientY;
    prevStartY.current = e.touches[0].clientY;
    startTime.current = new Date().getTime();
    if (scrolling.current && (startTime.current - endTime.current) < i_accelerationT) {
      // user swiped while still animating, increase the multiplier for the offset
      multiplier.current += i_acceleration;
    } else {
      // else reset multiplier
      multiplier.current = 1;
    }
  };

  const onTouchMove = (e) => {
    // e.preventDefault();
    endTime.current = new Date().getTime();
    // if (endTime.current - startTime.current < 200) {
    //   return;
    // }
    // startTouchEnd.current = true;
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;
    const currentY = e.changedTouches[0].clientY;
    delta.current = (currentY - prevStartY.current);
    acc = Math.abs(delta.current / (endTime.current - startTime.current));

    container.scrollTo({
      top: 0,
      left: containerScrollPosition - delta.current,
    });
    prevStartY.current = e.touches[0].clientY;
  };
  const onTouchEnd = (e) => {
    startTouchEnd.current = true;
    // e.preventDefault();
    //
    // let touchEndTime = new Date().getTime();
    // const touchTime = touchEndTime - endTime.current; // calculate touchtime: the time between release and last movement
    // const i_maxOffset = projectpageRef.current.clientWidth * i_speedLimit; // (re)calculate max offset
    // // calculate the offset (the extra pixels for the momentum effect
    // let offset = Math.pow(acc, 2) * projectpageRef.current.clientWidth;
    // offset = (offset > i_maxOffset) ? i_maxOffset : offset;
    // offset = (delta.current < 0) ? -multiplier.current * offset : multiplier.current * offset;
    // // if the touchtime is low enough, the offset is not null and the offset is above the offsetThreshold, (re)set the animation parameters to include momentum
    // if ((touchTime < i_moveThreshold) && offset !== 0 && Math.abs(offset) > (i_offsetThreshold)) {
    //   // t: current time, b: beginning value, c: change in value, d: duration
    //   // t and d can be in frames or seconds/milliseconds
    //   const ts = (touchEndTime /= touchTime) * touchEndTime;
    //   const tc = ts * touchEndTime;
    //   offset = startTime.current + delta.current * (-1 * ts * ts + 4 * tc + -6 * ts + 4 * touchEndTime);
    //   multiplier.current = 1;
    // }
    //
    // // endTime.current = new Date().getTime();
    // // const timeDiff = (normalize((endTime.current - startTime.current)) ** 2) * 400;
    // // console.log('timeDiff', timeDiff);
    // const container = scrollRef.current;
    // const containerScrollPosition = scrollRef.current.scrollLeft;
    // const currentY = e.changedTouches[0].clientY;
    // // delta.current = (currentY - startY.current);
    //
    // // delta.current /= timeDiff;
    //
    // // console.log('end delta', delta.current);
    //
    // if (Math.abs(offset) > 10) {
    //   scrolling.current = true;
    // }
    // container.scrollTo({
    //   top: 0,
    //   left: containerScrollPosition - offset,
    //   behavior: 'smooth',
    // });
    // smoothscroll.polyfill();
    // scrolling.current = false;
  };
  // useEffect(() => {
  //   projectpageRef.current.addEventListener('touchstart', onTouchStart, { passive: true });
  //   projectpageRef.current.addEventListener('touchmove', onTouchMove, { passive: true });
  //   projectpageRef.current.addEventListener('touchend', onTouchEnd, { passive: true });
  // });
  useEffect(() => {
    let timer;
    scrollRef.current.addEventListener('scroll', () => {
      if (startTouchEnd.current) {
        clearTimeout(timer);

        timer = setTimeout(() => {
          scrolling.current = false;
        }, 300);
      }
    }, { passive: true });
    FastClick.attach(projectpageRef.current);
    // scrollRef.current.addEventListener('touchmove', (e) => { e.stopPropagation(); }, false);
    // projectpageRef.current.addEventListener('touchmove', onTouchMove, { passive: true });
    // projectpageRef.current.addEventListener('touchstart', onTouchStart, { passive: false });
    // projectpageRef.current.addEventListener('touchmove', onTouchMove, { passive: true });
    // projectpageRef.current.addEventListener('touchend', onTouchEnd, { passive: true });
    projectpageRef.current.addEventListener('wheel', (event) => event.preventDefault());
    projectpageRef.current.style.overflowY = 'hidden';
    async function getImages() {
      const numOfImages = await GetNumOfImages(projectId);
      for (let i = 0; i < numOfImages; i += 2) {
        if (i !== 0) {
          setNewImagesLoading(true);
        }
        const imageId = i + 1;
        const imagesUnsorted = await GetImages(projectId, imageId, 2);
        const imagesSorted = imagesUnsorted.sort((a, b) => ((parseInt(a.id) > parseInt(b.id)) ? 1 : ((parseInt(b.id) > parseInt(a.id)) ? -1 : 0)));
        if (i === 0) {
          imagesSorted.splice(1, 0, i);
          setImages(imagesSorted);
        } else {
          if (analysisCnt.current > i / 2) {
            imagesSorted.splice(0, 0, i / 2);
          }
          setImages((oldArray) => [...oldArray, ...imagesSorted]);
        }
      }
      setNewImagesLoading(false);
    }
    // if (!location.state) {
    indexRef.current = 'current';
    async function getProject() {
      const project = await GetProject(projectId);
      AddLanguageResources(project, indexRef.current);
      analysisCnt.current = project.analysis.replace(/[\r\n]{1,}/g, '\n').split('\n').length;
      projectRef.current = project;
      getImages();
    }
    getProject();
    // } else {
    //   indexRef.current = location.state.index;
    //   projectRef.current = location.state.project;
    //   getImages();
    // }
  }, []);

  function ImageDiv(image, index) {
    return (
      <Row className="project-image-row">
        { typeof image === 'object' && (
        <div
          className="project-image-wrap"
        >
          <Image
            className="project-image"
            src={`data:image/jpeg;base64,${image.src}`}
            height="100%"
            key={`image${index}`}
            preview={{
              mask: null,
            }}
          />
        </div>
        )}
        { typeof image === 'number' && (
        <div
          className="project-analysis-wrap"
        >
          <div className="project-analysis" key={`image${index}-${image}`}>
            {t(`analysis${indexRef.current}-${image}`)}
          </div>
        </div>
        )}
      </Row>
    );
  }

  function setDesignTeam(designTeam) {
    const team = t(`design_team${designTeam}`).split(',');
    const teamItems = [];

    team.forEach((value, index) => {
      teamItems.push(<Row className="designteam" key={`team-row-${index}`}>{value}</Row>);
    });
    return (
      teamItems
    );
  }

  // Info to Description transition
  // {scrollPos > window.innerWidth
  //               && (
  //               <div>
  //                 <Row className="info-row">
  //                   <Col span={9}>
  //                     {t(`description${indexRef.current}`)}
  //                   </Col>
  //                 </Row>
  //               </div>
  //               ) }

  return (
    <div className="projectpage" ref={projectpageRef} onWheel={onWheel}>
      <div className="spin">
        <Spin spinning={!images} indicator={loadingIcon} />
      </div>
      <div className="project" ref={scrollRef}>
        <div className="images">
          <Image.PreviewGroup>
            <Row className="images-row">
              { images
              && (
              <Col className="image-col new-spin">
                <div className="project-title-wrap">
                  <div className="project-title">
                    <div className="project-info">
                      {t(`title${indexRef.current}`)}
                    </div>
                    <div className="project-infos">
                      {t(`location${indexRef.current}`)}
                    </div>
                  </div>
                </div>
              </Col>
              )}
              { images
                && images.map((image, index) => (
                  <Col className="image-col">
                    {ImageDiv(image, index)}
                  </Col>
                ))}
              { newImagesLoading
                  && (
                  <Col className="image-col new-spin">
                    <Spin spinning={newImagesLoading} indicator={loadingIcon} />
                  </Col>
                  )}
              { images
              && (
              <Col className="image-col new-spin">
                <div className="project-info-wrap">
                  <div className="project-infos">
                    <Row className="info-headers">
                      {t('location')}
                    </Row>
                    <Row className="info-value">
                      {t(`location${indexRef.current}`)}
                    </Row>
                    <Row className="info-headers">
                      {t('type')}
                    </Row>
                    <Row className="info-value">
                      {t(`category${indexRef.current}`)}
                    </Row>
                    <Row className="info-headers">
                      {t('year')}
                    </Row>
                    <Row className="info-value">
                      {projectRef.current.year}
                    </Row>
                    <Row className="info-headers">
                      {t('size')}
                    </Row>
                    <Row className="info-value">
                      {projectRef.current.size}
                      m²
                    </Row>
                    <Row className="info-headers">
                      {t('design_team')}
                    </Row>
                    {setDesignTeam(indexRef.current)}
                    <Row className="info-headers">
                      {t('construction_team')}
                    </Row>
                    <Row className="info-value">
                      {t(`construction_team${indexRef.current}`)}
                    </Row>
                    <Row className="info-headers">
                      {t('photographer')}
                    </Row>
                    <Row className="info-value">
                      {t(`photographer${indexRef.current}`)}
                    </Row>
                  </div>
                </div>
              </Col>
              )}
            </Row>
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
