import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
import './layout.css';
import { LoginProvider } from '../loginContext';

function Layout(props) {
  const [loginState, setLoginState] = React.useState(false);
  const { children } = props;
  return (
    <>
      <LoginProvider value={[loginState, setLoginState]}>
        <div className="main">
          <Header />
          {children}
          <Footer />
        </div>
      </LoginProvider>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
