import React, { useEffect, useRef, useState } from 'react';
import { navigate, Link } from '@reach/router';
import { Spin, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { GetProjects, AddLanguageResources, sleep } from '../utils';
import './gridpage.css';
import './editprojectspage.css';

const loadingIcon = <LoadingOutlined style={{ fontSize: 42, color: '#232323', marginTop: 35 }} spin />;

function GridPage() {
  const { t } = useTranslation();
  const gridpageRef = useRef(null);
  const scrollRef = useRef(null);
  const allRef = useRef();
  const resRef = useRef();
  const comRef = useRef();
  const accRef = useRef();
  const startX = useRef(null);
  const completedRef = useRef();
  const ongoingRef = useRef();
  const [projects, setProjects] = useState('');
  const [showResults, setShowResults] = React.useState(-1);
  const allProjects = useRef([]);
  const category = useRef('');
  const status = useRef('');
  const imageRef = useRef();

  useEffect(() => {
    if (!document.cookie) {
      navigate('/login/');
    }
  });

  const onWheel = (e) => {
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;

    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
    });
  };

  const onTouchStart = (e) => {
    startX.current = e.touches[0].pageY;
  };

  const onTouchMove = (e) => {
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;
    const currentY = e.touches[0].pageY;
    const delta = (currentY - startX.current) * 0.1;

    container.scrollTo({
      top: 0,
      left: containerScrollPosition - delta,
    });
  };
  const changeFilter = (filter) => {
    if (filter === 'all') {
      allRef.current.style.color = '#242424';
      resRef.current.style.color = '#d3d3d3';
      comRef.current.style.color = '#d3d3d3';
      accRef.current.style.color = '#d3d3d3';
      completedRef.current.style.color = '#d3d3d3';
      ongoingRef.current.style.color = '#d3d3d3';
      category.current = '';
      status.current = '';
      setProjects(allProjects.current);
    } else if (filter === 'residential') {
      resRef.current.style.color = '#242424';
      allRef.current.style.color = '#d3d3d3';
      comRef.current.style.color = '#d3d3d3';
      accRef.current.style.color = '#d3d3d3';
      category.current = 'Residential';
    } else if (filter === 'commercial') {
      comRef.current.style.color = '#242424';
      allRef.current.style.color = '#d3d3d3';
      resRef.current.style.color = '#d3d3d3';
      accRef.current.style.color = '#d3d3d3';
      category.current = 'Commercial';
    } else if (filter === 'accommodation') {
      accRef.current.style.color = '#242424';
      resRef.current.style.color = '#d3d3d3';
      allRef.current.style.color = '#d3d3d3';
      comRef.current.style.color = '#d3d3d3';
      category.current = 'Accomodation';
    } else if (filter === 'completed') {
      completedRef.current.style.color = '#242424';
      allRef.current.style.color = '#d3d3d3';
      ongoingRef.current.style.color = '#d3d3d3';
      status.current = 'completed';
    } else if (filter === 'ongoing') {
      ongoingRef.current.style.color = '#242424';
      allRef.current.style.color = '#d3d3d3';
      completedRef.current.style.color = '#d3d3d3';
      status.current = 'ongoing';
    }
    setProjects(allProjects.current.filter((item) => {
      if (category.current && status.current) {
        return (item.category === category.current && item.status === status.current);
      } if (category.current) {
        return (item.category === category.current);
      } if (status.current) {
        return (item.status === status.current);
      }
      return true;
    }));
  };
  useEffect(() => {
    gridpageRef.current.addEventListener('wheel', (event) => event.preventDefault());
    gridpageRef.current.style.overflowY = 'hidden';
    async function getProjects() {
      const projectsUnsorted = await GetProjects(true);
      allProjects.current = projectsUnsorted.sort((a, b) => ((a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0)));
      setProjects(allProjects.current);
    }
    if (document.cookie) {
      getProjects();
    }
    allRef.current.style.color = '#242424';
  }, []);

  async function imageHover(index) {
    setShowResults(index);
  }

  function ImageDiv(project, index) {
    return (
      <Row className="image-row">
        <Link to={`/editproject/${project.name}`} state={{ project, index }} className="image-link">
          <div
            className="image-wrap"
            onMouseEnter={() => imageHover(index)}
            onMouseLeave={() => imageHover(-1)}
          >
            <img className="image" ref={imageRef} src={`data:image/jpeg;base64,${project.thumbnail}`} height="100%" key={`image${index}`} />

            <div className="hover-image" key={`himage${index}`}>
              <div className="hover-image-header" key={`himageh${index}`}>
                <div>{t(`title${index}`)}</div>
              </div>
              <div className="hover-image-footer" key={`himagef${index}`}>
                <Row className="grid-row">
                  <Col>
                    <div className="cols">
                      {t(`category${index}`)}
                    </div>
                  </Col>
                  <Col>
                    <div className="cols">
                      {t(`location${index}`)}
                    </div>
                  </Col>
                  <Col>
                    <div className="cols">
                      {project.year}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Link>
      </Row>
    );
  }

  return (
    <>
      <div className="gridpage" ref={gridpageRef} onWheel={onWheel}>
        <div className="spin">
          <Spin spinning={!projects} indicator={loadingIcon} />
        </div>
        <div className="edit-projects">
          Edit Projects
        </div>
        <div className="edit-filters-wrap">
          <div className="filters">
            <Row>
              <Col style={{ marginRight: '16px' }}>
                <span className="filter" ref={allRef} onClick={() => changeFilter('all')}>{t('all')}</span>
              </Col>
              <Col style={{ marginRight: '16px' }}>
                <span className="filter" ref={resRef} onClick={() => changeFilter('residential')}>
                  {t('residential')}
                </span>
                &ensp;
                <span className="filter" ref={comRef} onClick={() => changeFilter('commercial')}>{t('commercial')}</span>
                &ensp;
                <span className="filter" ref={accRef} onClick={() => changeFilter('accommodation')}>{t('accommodation')}</span>
              </Col>
              <Col>
                <span className="filter" ref={completedRef} onClick={() => changeFilter('completed')}>
                  {t('completed')}
                </span>
                &ensp;
                <span className="filter" ref={ongoingRef} onClick={() => changeFilter('ongoing')}>{t('ongoing')}</span>
              </Col>
            </Row>
          </div>
        </div>
        <div className="grid-projects" ref={scrollRef}>
          <div className="projects">
            <Row className="projects-row">
              { projects
              && projects.map((project, index, list) => {
                if (index % 2 === 0) {
                  AddLanguageResources(project, index);
                  if (list[index + 1]) {
                    AddLanguageResources(list[index + 1], index + 1);
                    return (
                      <Col key={`col${index}`} className="projects-col">
                        {ImageDiv(project, index)}
                        {ImageDiv(list[index + 1], index + 1)}
                      </Col>
                    );
                  }
                  return (
                    <Col className="projects-col">
                      {ImageDiv(project, index)}
                    </Col>
                  );
                }
              })}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default GridPage;
